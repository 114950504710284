import { Entities } from '@inwink/entities/entities';
import { getEntityPredicate } from '../../../../data/entityfilters';
import { SortAndFilterItems } from '../../../../components/dynamicpage/teaseritems.props';
import { States } from '../../../../services/services';

export function getHomeSpeakers(eventdata: States.IEventDataStore): Entities.ISpeaker[] {
    let res = [];
    if (eventdata?.speakers?.data) {
        res = Array.prototype.slice.call(eventdata.speakers.data);
    }
    return res;
}

export function getSpeakersByIds(speakerids: { id: string }[], eventdata: States.IEventDataStore): Entities.ISpeaker[] {
    const res = [];
    if (speakerids && speakerids.length) {
        speakerids.forEach((s) => {
            const speakers = eventdata.speakers.data.filter((speaker) => s.id === speaker.id)[0];
            if (speakers) res.push(speakers);
        });
    }
    return [...res];
}

export function getSessionSpeakers(session: Entities.ISession, eventdata: States.IEventDataStore): Entities.ISpeaker[] {
    const res = [];

    if (session && session.speakers) {
        session.speakers.forEach((s) => {
            const speaker = eventdata.speakers.data.find((sp) => sp.id === s.id);
            if (speaker) {
                res.push(speaker);
            }
        });
    }

    return res;
}

export function getEventThemeSpeakers(eventTheme: Entities.IEventTheme, eventdata: States.IEventDataStore) {
    const res: Entities.ISpeaker[] = [];
    if (eventTheme) {
        const index = {};
        const sessions = eventdata.sessions.data.filter((s) => {
            return s.eventThemes.some((et) => et && et.id === eventTheme.id);
        });

        sessions.forEach((s) => {
            if (s.speakers && s.speakers.length) {
                s.speakers.forEach((sp) => {
                    if (!index[sp.id]) {
                        const speaker = eventdata.speakers.data.find((sessionspeaker) => sessionspeaker.id === sp.id);
                        if (speaker) {
                            if (!index[speaker.id]) {
                                index[speaker.id] = true;
                                res.push(speaker);
                            }
                        }
                    }
                });
            }
        });

        res.sort((a, b) => {
            if (!a || !b || !a.lastname || !b.lastname) return 0;
            return a.lastname.localeCompare(b.lastname);
        });
    }

    return res;
}

export function getJourneySpeakers(journey: Entities.IJourney, eventdata: States.IEventDataStore): Entities.ISpeaker[] {
    let res = [];

    const speakers = eventdata.speakers.data;
    if (speakers) {
        res = speakers?.filter((sp) => sp.journeys.length > 0 && sp.journeys?.some((j) => j.id === journey.id));
    }

    return res;
}

export function getRecommendationPredicate(user: States.IAppUserState,
    eventdata: States.IEventDataStore, filter: any, isTeaser?: boolean): (speaker: Entities.ISpeaker) => boolean {
    let recommendationPredicate: (speaker: Entities.ISpeaker) => boolean = () => true;
    if (user?.checked && (filter?.my_recommendations || isTeaser)) {
        const favoriteEventThemes = user?.currentUser?.data?.favoriteEventThemes?.data;
        const favoriteEventThemeIds: string[] = favoriteEventThemes?.map((et) => et.eventThemeId)
            || [];

        recommendationPredicate = (speaker) => {
            return speaker.sessions?.length &&
                speaker.sessions.some((s) => {
                    const session = eventdata?.sessions?.data?.filter((e) => e.id === s.id)[0];
                    return session?.eventThemes?.length && session.eventThemes
                        .some((e) => favoriteEventThemeIds.indexOf(e.id) > -1);
                });
        };
    }

    return recommendationPredicate;
}

export function filterSpeakers(
    user: States.IAppUserState,
    i18n: Entities.i18nService,
    eventdata: States.IEventDataStore,
    filter: any,
    fieldtemplate: Entities.IFieldTemplate,
    searchable: Entities.IFilterTemplate,
    properties: any
): Entities.ISpeaker[] {
    const predicate = getEntityPredicate<Entities.ISpeaker>(filter, fieldtemplate, searchable);
    const recommendationPredicate = getRecommendationPredicate(user, eventdata, filter);
    const res = eventdata.speakers.data.filter((s) => predicate(s) && recommendationPredicate(s));
    let asArray = Array.prototype.slice.call(res) as Entities.ISpeaker[];
    asArray = asArray.sort((a, b) => {
        if (!a || !b || !a.lastname || !b.lastname) return 0;
        return a.lastname.localeCompare(b.lastname);
    });
    return SortAndFilterItems(user, i18n, asArray, properties, false, null, fieldtemplate.workTemplate);
}
